<template>
  <div class="iframeBox">
    <iframe :src="iframeUrl"></iframe>
  </div>
</template>
<script>
export default {
  data() {
    return {
      iframeUrl: '',
    }
  },
  mounted() {
    this.iframeUrl = this.$route.query.url;
  }
}
</script>
<style lang="scss" scoped>
.iframeBox {
  width: 100%;
  height: 100%;
}
iframe {
  width: 100%;
  height: 100%;
}
</style>
